.title {
    padding-top: 3rem;
    text-align: center;
    margin-bottom: 20px;
}

.background {
    width: 100%;
}

.container {
    width: 100%;
    max-width: 1440px !important;
    margin: auto;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
}

.zoomedRibbonElement {
    display: flex;
    justify-content: center;
}

.image {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.mask {
    width: 100%;
    transition: all 0.25s linear;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 2.2rem;
    position: absolute;
    top: 0;
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    text-align: center;
}

.mask:hover {
    transition: all 0.25s ease-out;
    background-color: rgba(255, 255, 255, 0.6);
}

.mask > div {
    transform: translateY(-2rem);
}

.modalImg {
    justify-content: center;
}

.itemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.carouselItemContainer {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carouselItemImage {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
}

.sCarousel {
    max-width: 250px;
}

.sCarouselItem {
    display: flex !important;
    justify-content: center;
}

.sCarouselLegend {
    min-height: 3rem;
    margin-top: 1rem;
    text-align: center;
}

@media (max-width: 1024px) {
    .backgroundElement {
        max-width: 150px;
    }
    .itemContainer {
        justify-content: center;
        max-width: 942px;
        margin: auto;
    }
}

@media (max-width: 768px) {
    .itemContainer {
        max-width: 680px;
    }

    .mask {
        display: none;
        visibility: hidden;
    }

    .legend {
        visibility: visible;
        margin-top: 10px;
    }

    .mask:hover {
        display: none;
        visibility: hidden;
    }
}

@media (min-width: 768px) {
    .mask {
        visibility: visible;
    }

    .legend {
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 640px) {
    .itemContainer {
        max-width: 273px;
    }
}
