.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-bottom: 1.4rem;
    font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
}

.itemContainer {
    width: auto;
    display: flex;
    flex-flow: row wrap;
    margin-left: calc(-1em - 8px);
    margin-right: calc(-1em - 8px);
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: calc(50% - 16px);
}

.item {
    margin: 12px 8px 0 8px;
}

.item img {
    max-width: 100%;
    object-fit: cover;
}

.itemText {
    margin-top: 4px;
    text-align: center;
    font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
}

@media (min-width: 480px) {
    .halfWidth {
        width: calc(50% - 16px);
    }

    .item {
        margin-top: 32px;
    }
}

@media (min-width: 768px) {
    .itemContainer {
        width: 100%;
        justify-content: center;
        margin-left: unset;
        margin-right: unset;
    }

    .fullWidth,
    .halfWidth {
        width: unset;
    }

    .item {
        width: calc(20% - 16px);
    }
}
