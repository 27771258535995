.loader {
  width: 100%;
  height: 634px;
}

.titleWrapper {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .titleWrapper {
    max-height: 200px;
    overflow: hidden;
    transition: none;
  }

  .titleWrapper:hover,
  .titleWrapper:focus {
    max-height: 2000px;
    overflow: unset;
    transition: none;
  }
}

.titleWrapper:hover,
.titleWrapper:focus {
  max-height: 2000px;
  overflow: unset;
  transition: max-height 0.5s ease-in;
}

@media only screen and (max-width: 1024px) {
  @media screen and (prefers-reduced-motion: reduce) {
    .titleWrapper {
      max-height: 2000px;
      margin-bottom: 20px;
      overflow: unset;
      transition: none;
    }
  }
  .titleWrapper {
    max-height: 2000px;
    margin-bottom: 20px;
    overflow: unset;
    transition: max-height 0.5s ease-in;
  }
}

.parallax {
  position: relative;
}

.parallax_layer {
  margin-top: -139px;
  padding-top: 139px;
  position: relative;
}
