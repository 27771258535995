.titleContainer {
  text-align: center;
  margin-bottom: 60px;
}

.title {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  font-size: 2.6rem;
  font-weight: normal;
}

.backgroundColor {
  position: relative;
}

.backgroundImage {
  position: absolute;
  width: 95%;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.imageElement {
  margin-bottom: 15px;
}
@media only screen and (max-width: 1024px) {
  .backgroundImage {
    visibility: hidden;
    display: none;
  }
}
.wrapper {
  margin-top: 20px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}

.backgroundElement {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  text-align: center;
  margin-bottom: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageElement {
  max-width: 70%;
  max-height: 70%;
  margin-bottom: 10px;
}

.titleElement {
  width: 90%;
  margin-left: 5%;
  height: 100%;
  font-size: 1.6rem;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
}

.subTitleElement {
  width: 90%;
  margin-left: 5%;
  height: 100%;
  font-size: 1.4rem;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

.itemColorContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.itemColor {
  flex: 1;
}

@media (max-width: 1024px) {
  .itemColor {
    min-width: 50%;
  }
}

@media (max-width: 767px) {
  .imageElement {
    max-width: 80px;
    max-height: 80px;
  }
}
