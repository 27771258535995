.news {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height: 100%;
}

.imgContainer.alt {
    position: relative;
}

.image {
    width: 100%;
}

.image.alt {
    display: block;
}

.textContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.title {
    margin-top: 20px;
    padding-top: 15px;
}

.title h1 {
    font-family: UrbanistBlack, Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.title.alt {
    margin-top: 0;
    padding-top: 0;
    max-width: 75%;
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
    font-size: 2.4rem;
}

.titleContainer,
.filter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.filter {
    background-color: rgb(0, 0, 0, 0.5);
}

.subTitle {
    padding: 10px 20px;
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
}

.button {
    margin-top: 10px;
    padding-bottom: 15px;
    display: flex;
    justify-self: flex-end;
}

@media (max-width: 767px) {
    .news {
        margin-bottom: 2rem;
    }
    .title {
        margin-top: 1rem;
        padding-top: 0;
    }
    .title.alt {
        margin-top: 0;
        max-width: 90%;
    }

    .subTitle {
        padding: 10px;
    }
}
