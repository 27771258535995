.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 3rem 20rem;
  text-align: center;
  max-width: 1440px !important;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 3rem;
}

.carousel {
  width: 110%;
}

.title {
  width: 100%;
  z-index: 3;
}

.background {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 5rem 0;
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 3rem 10rem;
    margin-bottom: 10px;
  }

  .background {
    padding: 4rem 0;
  }
}

@media (max-width: 768px) {
  .textContent {
    padding: 0 5rem;
    margin-bottom: 1rem;
  }

  .wrapper {
    padding: 3rem 4rem;
    margin-bottom: 10px;
  }

  .background {
    padding: 3rem 0;
  }
}

@media (max-width: 320px) {
  .wrapper {
    padding: 1rem 1rem;
    margin-bottom: 10px;
  }

  .background {
    padding: 1.5rem 0;
  }
}
