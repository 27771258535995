.wrapper {
    width: 100%;
    margin: auto;
    align-items: center;
    padding-bottom: 3rem;
}

.content {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    align-items: center;
    text-align: center;
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

.mainPicture {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.mainPicture img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.pictureContent {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-top: 3rem;
    padding: 0 2rem;
}

.itemContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.item {
    display: inline-block;
    position: relative;
}

.item img {
    max-width: 100%;
    max-height: 100%;
}

.pictureCaption {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.CTA {
    padding-top: 3rem;
}

.trendElementLoader {
    min-width: 100px;
}

@media (max-width: 768px) {
    .pictureContent {
        grid-template-columns: 1fr;
    }

    .itemContainer {
        max-width: 100%;
    }

    .pictureCaption {
        position: relative;
    }
}
