.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trendBloc {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
