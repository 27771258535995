.thumbContainer {
  width: calc(100% / 3 - 1rem);
  margin: 0.5rem;
}
.thumbImgContainer {
  width: 100%;
  background-color: white;
}
.thumbLogoContainer {
  display: flex;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: absolute;
}

.thumbImage {
  opacity: 1;
  transition: opacitry 0.25s;
  -webkit-transition: opacity 0.25s;
}

.thumbImage:hover {
  opacity: 0;
}

.thumbLegendContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbTitle {
  text-transform: uppercase;
}

.thumbSubTitle {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

@media (max-width: 1023px) {
  .thumbContainer {
    width: calc(100% / 2 - 1rem);
  }
}
@media (max-width: 767px) {
  .thumbContainer {
    width: 100%;
  }
}
