.title {
  font-size: 2.6rem;
  text-align: center;
  margin-bottom: 20px;
}

.background {
  width: 100%;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.content {
  width: 100%;
  max-width: 1440px !important;
  margin: auto;
  display: flex;
}

.container {
  width: 100%;
  max-width: 1200px !important;
  margin: auto;
  padding: 0 3rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageContainer {
  max-width: 800px;
  margin: 0 5px;
}

.item {
  margin: 2rem;
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
  }
  .imageContainer {
    max-width: 100%;
    width: 100%;
    margin: 1rem 0;
  }
}
