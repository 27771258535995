.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 3rem 20rem;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1440px !important;
  margin: auto;
}

.description {
  line-height: 1.3;
}

.textContent {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 3rem;
}

.carousel {
  width: 100%;
  margin-bottom: 2.5rem;
}

.superTitle {
  width: 100%;
  font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 3rem 10rem;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .superTitle {
    margin-top: 0;
  }

  .textContent {
    padding: 0;
  }

  .wrapper {
    padding: 3rem 4rem;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .wrapper {
    padding: 1rem 1rem;
    margin-bottom: 10px;
  }
}
