.titleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-bottom: 1.5rem;
}

.subtitle {
    width: 50%;
    margin-top: 2.5rem;
}

.tags {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.tag {
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
    padding: 0.4rem 1.4rem;
    border-radius: 4rem;
}
.tag:hover {
    cursor: pointer;
    animation-name: scaleIn;
    animation-duration: 0.5s;
    transform: scale(1.1);
}

.tag + .tag {
    margin-left: 1rem;
}

.news {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 6rem 2rem;
    grid-auto-rows: minmax(300px, auto);
    margin-top: 2rem;
}

.newsItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.newsItemTitle,
.newsItemSubtitle {
    display: flex;
    margin-top: 1rem;
    overflow-wrap: break-word;
}

.btnContainer > :nth-child(n) {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.seeMore {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

@media (max-width: 768px) {
    .subtitle {
        width: unset;
    }

    .news {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@keyframes scaleIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}