.container {
  max-width: 950px;
  margin: 0 auto;
  padding: 20px;
}
.assetContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}
.assetSection {
  background-color: #fff8f5;
}

.assetTitle {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 1.6rem;
  text-align: center;
}

.assetText {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .assetText {
    width: 100%;
  }
}
