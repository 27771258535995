.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  margin-top: 25px;
}

.newsContainer {
  max-width: 1200px;
  margin: auto;
}

@media (max-width: 767px) {
  .description {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
