.description {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  margin: auto;
  max-width: 950px;
  font-size: 1.4rem;
}
.description.large {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  margin: auto;
  max-width: 100%;
}

@media (max-width: 1024px) {
  .description {
    width: 100%;
    font-size: 1.6rem;
  }
}
