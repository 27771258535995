.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 3rem 10rem;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1440px !important;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 1em;
}

.carousel {
  width: 100%;
  margin-bottom: 2.5rem;
}

.title {
  margin-bottom: 0.5em;
  font-size: 2.6rem;
}

@media (max-width: 768px) {
  .textContent {
    margin-bottom: 2rem;
  }

  .wrapper {
    padding: 1rem 1rem;
    margin-bottom: 10px;
  }
}

/* .selector css */

.selector {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  min-width: 7em !important;
  border-radius: 4px !important;
  border: solid 1px #000 !important;
  text-align: center !important;
  padding: 6px 30px 6px 9px !important;
  min-height: 1em !important;
  margin-bottom: 1em;
  width: 180px;
}
