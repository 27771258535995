.container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.iFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
