.articleCard {
    text-align: center;
    padding: 2em 1em 5em;
    margin-top: 1rem;
    height: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d3d3d3;
    flex: 1;
    height: 100%;
}

.articleCardImage {
    width: 100%;
    max-width: 450px;
    margin-top: -20%;
}

.articleCardHeader {
    font-size: 2.6rem;
    font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
    text-align: center;
    padding: 10px;
}

.articleCardSubHeader {
    font-size: 1.4rem;
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    padding: 10px;
    margin-bottom: 2em;
}

.articleCardButtonCTA {
    padding: 1em 1em 2em;
    display: flex;
}

@media (max-width: 1024px) {
    .articleCard {
        margin-bottom: 1em;
        padding: 2em 1em 1em;
    }

    .articleCardSubHeader {
        font-size: 2rem;
        font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
        padding: 10px;
        margin-bottom: 2em;
    }
}

@media (max-width: 767px) {
    .articleCard {
        margin-top: 0;
        margin-bottom: 0;
        padding: 2em 1em 3em;
    }
}
