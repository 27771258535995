.rootContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}

.background {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.backgroundPicture {
  width: 100%;
  background-color: grey;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.backgroundPicture:hover {
  width: 102%;
}

.colorWrapper {
  display: flex;
  padding: 50px;
  position: relative;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.ctaButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ctaButton {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.titre {
  font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
}

.texte {
  size: 18px;
}

@media only screen and (min-width: 1024px) {
  .titre,
  .texte {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .background {
    max-height: 640px;
  }

  .titre,
  .texte {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .background {
    max-height: 640px;
  }

  .titre {
    margin-bottom: 8px;
  }

  .texte {
    margin-bottom: 24px;
  }

  .ctaButtonContainer {
    flex-direction: column;
  }
}

@media only screen and (max-width: 638px) {
  .background {
    max-height: 600px;
  }

  .titre {
    margin-bottom: 8px;
  }

  .texte {
    margin-bottom: 24px;
  }
}
