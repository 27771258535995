.title {
  font-size: 2.6rem;
  text-align: center;
  margin-bottom: 20px;
}

.background {
  width: 100%;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.content {
  width: 100%;
  max-width: 1440px !important;
  margin: auto;
  display: flex;
}

.container {
  width: 100%;
  max-width: 1440px !important;
  margin: auto;
  padding-bottom: 3rem;
}

.itemContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}

.item {
  margin: 2rem;
}

@media (max-width: 1024px) {
  .itemContainer {
    justify-content: center;
  }
}
