.rootContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}

.isChild {
  height: 100%;
  justify-content: unset;
}

.background {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.outerWrapper {
  width: 80%;
  height: 100%;
  max-width: 1440px !important;
  margin: auto;
  display: flex;
}

.wrapper-contenu {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.inner-wrapper {
  height: 100%;
}

@media only screen and (min-width: 1024px) {
  .wrapper-contenu {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .background {
    max-height: 640px;
  }

  .outerWrapper {
    align-items: center !important;
    justify-content: center !important;
  }

  .wrapper-contenu {
    width: 560px !important;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rootContainer.fullWidth {
    width: 100vw;
    margin-top: 1rem;
  }

  .wrapper-contenu {
    width: 474px !important;
  }
}

@media only screen and (max-width: 638px) {
  .background {
    max-height: 600px;
  }

  .wrapper-contenu {
    width: 90% !important;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
