.image {
  position: relative;
  height: 100%;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.title {
  color: black;
  font-size: 3rem;
  margin: 0;
  padding: 0;
  z-index: 1;
  margin-left: 45%;
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  position: relative;
  top: 40px;
}

.imageContainer {
  text-align: center;
}

.wrapper {
  margin-bottom: 23em;
}

.image1,
.image2,
.image3 {
  max-width: 30%;
  position: relative;
  z-index: 1;
}

.image1 {
  transform: translateX(10%);
}

.image2 {
  transform: translateY(66%);
  z-index: 2;
}

.image3 {
  transform: translate(-10%, 33%);
}

@media (max-width: 1024px) {
  .wrapper {
    margin-bottom: 20em;
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-bottom: 0;
  }

  .image1,
  .image2 {
    max-width: 50%;
  }

  .image1 {
    transform: translateX(5%);
  }

  .image2 {
    transform: translate(-5%, 40%);
    z-index: 2;
  }

  .image3 {
    visibility: hidden;
  }

  .title {
    margin-left: 60%;
  }
}
