.root {
    width: 100%;
    margin-top: 3rem;
    padding: 3rem 0;
}

.title {
    margin-bottom: 2rem;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.itemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contactInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.leftBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}

.middleBlock {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
}

.addrIcon {
    max-height: 60px;
    max-width: 60px;
    margin-right: 1rem;
}

.icon {
    max-height: 3rem;
    max-width: 3rem;
    margin-right: 1rem;
}

.link {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
    }
    .itemContainer {
        margin-bottom: 1rem;
    }
    .middleBlock {
        flex-direction: row;
    }
    .middleBlock .icon {
        max-height: 60px;
        max-width: 60px;
        margin-right: unset;
        margin: 0 1rem;
    }
    .link {
        justify-content: center;
    }
    .ctaButton {
        margin-top: 1rem;
    }
    .hideable {
        display: none;
    }
}
