.root {
    width: 100%;
    max-width: 1440px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem 4rem;
    text-align: center;
    padding: 0 2rem;
}

.item {
    width: 100%;
    max-width: 18rem;
    margin: 3rem 0
}

.imgContainer {
    aspect-ratio: 1 / 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid black;
}

.text {
    margin-top: 1rem;
}

@media (max-width: 1024px) {
    .root {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 767px) {
    .root {
        grid-template-columns: repeat(2, 1fr);
    }
}
