.line {
    width: 100%;
}

.imageLineContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2rem;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    overflow: hidden;
}

.container {
    position: relative;
    margin-bottom: 3rem;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.textContent {
    margin-bottom: 2rem;
}

.captionText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
}
.title {
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
    font-size: 3rem;
    text-transform: uppercase;
}

.description {
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
}

.halfWidth {
    width: 50%;
}

.halfWidth:first-of-type {
    margin-right: 10px;
}

.halfWidth:last-of-type {
    margin-left: 10px;
}

.halfWidth .title {
    font-size: 2.4rem;
}

.cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 2.5rem;
    width: 100%;
    height: 100%;
    color: #fff;
}

.cardContent.filter {
    background: rgba(0, 0, 0, 0.5);
}
