.zBlockRoot {
    width: 100%;
    max-width: 1440px !important;
}

.zBlockTitle {
    font-size: 2.4rem;
    text-align: center;
    text-transform: uppercase;
}

.zElems {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.zElemContainer {
    width: calc(100% / 6 - 2rem);
    background-color: white;
}

.zElemContainer:nth-child(7) {
    margin-left: 0 !important;
}

.zElemContainer + .zElemContainer {
    margin-left: 1.5rem;
}

.zElemContainer:nth-child(n + 7) {
    margin-top: 3rem;
}

.zElemRoot {
    height: 100%;
    cursor: pointer;
}

.zElemModalTrigger {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.zElemTriggerText {
    text-align: center;
    padding: 1rem;
}

.zElemTitle {
    font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    text-transform: uppercase;
}
.zElemLegend {
    font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
}
.zElemDescription {
    font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
}

.zElemModalContent {
    height: 90vh;
    flex-direction: column !important;
    justify-content: center;
}

.zElemModalContentItemContainer {
    height: calc(90vh * 0.90);
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.zElemModalContentImg {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.zElemModalContentLegend {
    font-size: 1.8rem;
}

.sliderDots {
  bottom: 25px !important;
}

.sliderDots li .sliderDot {
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  height: 12px;
  width: 12px;
  color: white;
}

.sliderDots li .sliderDot::before {
  opacity: 0;
  font-size: 12px;
  line-height: 20px;
  left: -3.75px;
  top: -2.5px;
  color: black !important;
}

.sliderDots li .sliderDot {
  border: 2px solid rgba(0, 0, 0, 0.5);
  color: black;
}

.sliderDots li.slick-active .sliderDot::before {
  opacity: 1;
}

.sliderDots li {
  width: 20px;
  height: 20px;
  margin: 0 2px;
  padding: 0px;
  cursor: pointer;
}

@media (max-width: 768px) {
    .zElemRoot {
        max-width: 100%;
    }

    .zElemContainer {
        width: calc(100% / 1.5);
        margin-top: 3rem;
    }
    .zElemContainer + .zElemContainer {
        margin-left: 0;
    }
}
