.container {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
}
.wrapper {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.leftWrapper,
.rightWrapper {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.leftTextWrapper {
  padding-right: 1rem;
}

.rightTextWrapper {
  padding-left: 1rem;
}

.picture img {
  width: 100%;
}
.title {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .leftWrapper,
  .rightWrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
