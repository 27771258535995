.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1440px !important;
    margin: auto;
}

.description {
    line-height: 1.3;
}

.textContent {
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 3rem;
}

.carousel {
    width: 100%;
}

.altMode {
    width: 200px;
}

.altMode img {
    aspect-ratio: 1;
    object-fit: none;
}

.superTitle {
    width: 100%;
    font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.title {
    margin-bottom: 1rem;
}

.ctaButtonContainer {
    margin-top: 2.5rem;
}

@media (max-width: 1024px) {
    .wrapper {
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .superTitle {
        margin-top: 0;
    }

    .textContent {
        padding: 0;
    }

    .wrapper {
        padding: 3rem;
        margin-bottom: 10px;
    }
}

@media (max-width: 320px) {
    .wrapper {
        padding: 1rem 1rem;
        margin-bottom: 10px;
    }
}
