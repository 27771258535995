.prefooter {
  width: 100%;
  margin: auto;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

.itemContainerLarge {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
}

.itemContainerSmall {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
}

.item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  padding: 3rem;
}

.item picture {
  display: flex;
  justify-content: center;
}

.item img {
  max-width: 150px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .itemContainer {
    flex-wrap: wrap;
  }

  .item {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .item img {
    max-width: 80px;
  }
}
