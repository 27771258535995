.wrapper {
  width: 100%;
  height: 100%;
  padding: 0 3px;
}

.imgContent {
  position: relative;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  max-width: 100%;
  width: 100%;
}

.legend {
  padding-bottom: 5px;
  margin-top: 5px;
}

.title {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.subTitle {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  font-weight: normal;
}
