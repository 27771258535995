.root {
  width: 100%;
  margin: auto;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

.content {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: auto;
  flex-wrap: wrap;
}
.content.square {
  justify-content: space-evenly;
}

.content:after {
  content: "";
  flex: 10 0 auto;
}
.content.square:after {
  content: unset;
  flex: unset;
}

.item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  padding: 1rem;
  max-width: 180px;
  margin: auto;
  flex-basis: 100%;
  justify-content: left;
}

.item img {
  max-width: 100%;
  object-fit: cover;
}

.fourItem {
  max-width: 25%;
  margin: 0 auto;
  justify-content: unset;
}

.sixItem {
  max-width: calc(100% / 6);
  margin: 0 auto;
  justify-content: unset;
}
.sixItem.square {
  max-width: 18rem;
  width: 100%;
  height: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.pictureCaption {
  margin-top: 5px;
}

@media (max-width: 1024px) {
  .fourItem {
    max-width: 50%;
  }
  .sixItem {
    max-width: calc(100% / 3);
  }
}

@media (max-width: 767px) {
  .content.fullWidth {
    width: auto;
    margin: -8px;
  }
  .content.square {
    padding: 0 1rem;
  }
  .item {
    max-width: 150px;
  }
  .item.fullWidth {
    padding: 0;
  }
  .fourItem {
    max-width: 50%;
  }
  .fourItem.fullWidth {
    max-width: calc(50% - 16px);
    margin: 8px;
  }
  .fourItem.highlight {
    max-width: 100%;
  }
  .sixItem {
    max-width: calc(100% / 3);
  }
  .sixItem.square {
    max-width: calc(90vw / 3 - 2rem);
    height: calc(90vw / 3 - 2rem);
    margin-bottom: 4rem;
  }
}
