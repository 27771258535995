.listContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}

.listContainer.fullWidth {
  width: 100%;
}

@media (max-width: 767px) {
  .listContainer {
    width: 100%;
  }
}
