.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.introText {
    max-width: 950px;
    margin-top: 20px;
}

.newsContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.newsWrapper {
    flex-basis: 33%;
    padding: 15px;
}

@media (max-width: 1024px) {
    .introText {
        margin-top: 10px;
    }

    .newsContainer {
        margin-top: 10px;
    }

    .newsWrapper {
        flex-grow: 1;
        padding: 4px;
    }
}

@media (max-width: 767px) {
    .introText {
        width: 100%;
    }

    .newsWrapper {
        flex-basis: 100%;
    }
}
