.root  {
     margin-top: 4rem;
}

.item {
    display: flex;
}

.item:nth-child(even) {
    flex-direction: row-reverse;
}
.item:nth-child(odd) {
    flex-direction: row;
}

.item:nth-child(even) > .textBlock {
    padding-left: 3rem;
}
.item:nth-child(odd) > .textBlock {
    padding-right: 3rem;
}

.item + .item {
    margin-top: 4rem;
}

.textBlock {
    width: 45%;
}

.text {
    margin-top: 3rem;
}

.picture {
    width: 55%;
}

@media (max-width: 768px) {
    .item:nth-child(even),
    .item:nth-child(odd) {
        flex-direction: column-reverse;
        align-items: center;
    }

    .item:nth-child(even) > .textBlock,
    .item:nth-child(odd) > .textBlock {
        padding: 0;
    }

    .item + .item {
        margin-top: 5rem;
    }

    .textBlock {
        width: 100%;
    }

    .textBlockH3 {
        margin-top: 1.5rem;
    }

    .text {
        margin-top: 1rem;
    }

    .picture {
        width: 100%;
    }
}
