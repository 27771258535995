.wrapper {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.picture1 {
  max-width: 33%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
}

.picture2 {
  max-width: 50%;
}

.textWrapper {
  visibility: visible;
  width: 33%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  right: 5%;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
}

.textMobile {
  display: none;
  width: 90%;
  margin-left: 5%;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  margin-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 500px;
  }
  .textMobile {
    display: block;
  }

  .textWrapper {
    visibility: hidden;
  }

  .picture1 {
    max-width: 40%;
  }

  .picture2 {
    max-width: 60%;
    margin-left: 30%;
  }
}
