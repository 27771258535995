.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsContainer {
  width: 1440px !important;
  margin: 100px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.newsWrapper {
  flex-basis: 33%;
  padding: 15px;
}

@media (max-width: 1024px) {
  .newsContainer {
    margin-top: 10px;
  }

  .newsWrapper {
    flex-basis: 50%;
    padding: 4px;
  }
}

@media (max-width: 767px) {
  .newsWrapper {
    flex-basis: 100%;
  }
}
