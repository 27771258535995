.title {
  font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif !important;
}

.text {
  size: 18px;
}

.ctaButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
  
.ctaButton {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}
  
@media only screen and (min-width: 1024px) {
  .title,
  .text,
  .tagline {
    margin-bottom: 20px;
  }
}
  
@media only screen and (max-width: 1024px) {
  .container {
      max-width: 1000px;
      margin: 20px auto 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
  }

  .title {
    margin-bottom: 0;
  }

  .text,
  .tagline {
    margin-bottom: 10px;
  }
  
  .colorChange {
    color: black !important;
  }

  .rightCta {
      margin-left: 10px;
  }
}
  
@media only screen and (max-width: 767px) { 
  .title {
    margin-bottom: 8px;
  }

  .text,
  .tagline {
    margin-bottom: 24px;
  }

  .ctaButtonContainer {
    flex-direction: column;
  }

  .rightCta {
     margin-left: 0;
  }
}