.background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  text-align: center;
  margin: auto;
  cursor: pointer;
  display: block;
}

.image {
  max-width: 100%;
  display: block;
}

.mask {
  width: 100%;
  transition: all 0.25s linear;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  position: absolute;
  top: 0;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

.title {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 2.6rem;
  margin-top: 2px;
}

.subTitle {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 1.8rem;
  margin-top: 2px;
}

.mask:hover {
  transition: all 0.25s ease-out;
  background-color: rgba(255, 255, 255, 0.6);
}

@media screen and (prefers-reduced-motion: reduce) {
  .mask:hover {
    transition: none;
    overflow: unset;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
